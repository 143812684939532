import React, {useEffect} from "react"
import Helmet from 'react-helmet';
import useSiteMetadata from '../hooks/use-sitemetadata';
import InitPanelScroll from '../utils/panelScroll';

import HomePanel from "../components/panels/homePanel"
import VideoPanel from "../components/panels/videoPanel"
import AboutPanel from "../components/panels/aboutPanel"
import GalleryPanel from "../components/panels/galleryPanel"
import MusicPanel from "../components/panels/musicPanel"
import ContactPanel from "../components/panels/contactPanel"
import SEO from "../components/seo"
import styled from '@emotion/styled'
import Footer from '../components/footer'
import BioPanel from "../components/panels/bioPanel";
import BioPanel2 from "../components/panels/bioPanel2";
import Header from '../components/header'

const Page = styled('div')`
  margin: auto;
  margin-top: calc(2.7rem + 1vw);
  position: relative;
`;

const IndexPage = () => {
  const { title, description, headerTitle, headerMenus } = useSiteMetadata();
  
  InitPanelScroll();

  let ticking = false;
  const handleScroll = (e) => {
    let lastScrollPos = window.scrollY
    if(!ticking){
      if(typeof window != 'undefined'){
        window.requestAnimationFrame( ()=>{
         // console.log(lastScrollPos)
          ticking = false
        })
        ticking = true
      }
    }
  }
  useEffect (() => {
    window.addEventListener('scroll', handleScroll)
  })


 return(
  <>
    <Helmet>
            <html lang="en" />
            <title>{title}</title>
            <meta name="description" content={description} />
    </Helmet>
  
    <SEO title="Home" />
    <Header siteTitle={headerTitle} menuItems={headerMenus} />
    <Page>
      <HomePanel />
      <BioPanel2 />
      <VideoPanel  videoPos="left" videoId="Kodaly" backColor="#fef9f3" />
      <VideoPanel  videoPos="right" videoId="BachSonata" backColor="white" />
      <VideoPanel  videoPos="left" videoId="Messiaen" backColor="#ede8e2" />
      
  
      
      <GalleryPanel />
      <ContactPanel />
    </Page> 
    <Footer />   
  </>
 )
  
}

export default IndexPage
