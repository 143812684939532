import PropTypes from "prop-types"
import React from "react"
import styled from '@emotion/styled'
import {breakpoint} from '../styles/breakpoints'
import scrollToAnchor from '../utils/scrollTo'

    const SiteHeader = styled('header')`
      id: 'XSiteHeader';
      position:fixed;
      z-index: 100;
      top: 0;
      overflow:hidden;
      width: 100%;
      height:  calc(2.7rem + 1vw);
      border-bottom: solid 1px grey;
      padding-bottom: calc(.3rem + 2vw);
      margin-bottom: 20px;
      padding-top: calc(.9rem + 1.2vw);
      background-color:white; 
    `;

    const SiteTitle = styled('label')`
    display: inline-block;
    width: 50%;
    font-size: calc(.5rem + 2.5vw);
    font-style: italic;
    padding-left: 5%;
    font-family: Palatino;
    cursor:default;

    @media only screen and ${breakpoint.tablet} {
    font-size: calc(1.3rem + 1vw);
    }
    `;

    const NavDiv = styled('div')`
    display: inline-block;
    width: 50%;
    `;

    const NavBlock = styled('nav')`
    padding-bottom: 3px;
    padding-right: calc(.2rem + 1vw);
    display:flex;
    display: -ms-flexbox;
    flex-direction: row;
    justify-content:flex-end;
    width: 100%;
    
    `;

    const Nav=styled('button')`
    font-size: calc(.5rem  + 1vw);
    font-family: Palatino;
    color: black;
    cursor:pointer;
    border:none;
    background-color: transparent; 
    font-style:italic;
    align-text: center;
    margin-bottom: calc(.3rem + 2vw);
    `;
    
    const Header = (props) => (
  <>
    <SiteHeader id="XSiteHeader">
        <SiteTitle>
             
        </SiteTitle>
        <NavDiv>
        <NavBlock>
          {props.menuItems.map((item, i) => (
            <Nav  key={i} style={{order:item.order}} onClick={()=>scrollToAnchor(item.label == 'Music'? 'Kodaly_panel': item.label.toLowerCase() + '_panel')} >
              {item.label}
            </Nav>
          ))}
        </NavBlock> 
        </NavDiv>
      </SiteHeader>
             
</>
)


Header.propTypes = {
  siteTitle: PropTypes.string,
  menuItems: PropTypes.array
}

export default Header
